<template>
    <section class="card double-bottom-space" v-if="formEdit">
        <div v-if="showPageLoader" class="loader">
            <div><i class="fas fa-circle-notch fa-spin"></i></div>
        </div>
        <nav class="ink-navigation back double-bottom-space" v-if="!registerComponent">
            <a href="/settings" class="back-button ink-button push-left">
                <span>Voltar</span>
            </a>
            <h3 class="section-title align-center">Dados da Conta</h3>
        </nav>

        <form class="ink-form">
            <error-for field="" :errors="Err" />

            <fieldset>
                <legend>Dados Gerais</legend>
                <div class="column-group gutters">
                    <div class="control-group required all-100" :class="getFieldClass('PublicName')">
                        <label for="PublicName">Designação Comercial (Nome da Empresa para os anúncios)</label>
                        <div class="control">
                            <input name="PublicName" id="PublicName" type="text" v-model="adv.PublicName" />
                        </div>
                        <error-for field="PublicName" :field-value="adv.PublicName" :errors="Err" />
                    </div>
                    <div class="control-group required all-75 small-100 tiny-100" :class="getFieldClass('TaxNumber')">
                        <label for="TaxNumber">Número de Contribuinte</label>
                        <div class="control">
                            <input name="TaxNumber" id="TaxNumber" type="text" v-model="adv.TaxNumber" @keypress="onKeyPressIsNumber($event)" maxlength="16"/>
                        </div>
                        <error-for field="TaxNumber" :field-value="adv.TaxNumber" :errors="Err" />
                    </div>

                    <div class="control-group required all-100" :class="getFieldClass('BillingName')">
                        <label for="BillingName">Designação Social (Entidade a Faturar)</label>
                        <div class="control">
                            <input name="BillingName" id="BillingName" type="text" v-model="adv.BillingName" />
                        </div>
                        <error-for field="BillingName" :field-value="adv.BillingName" :errors="Err" />
                    </div>
                    <div class="control-group required all-100" :class="getFieldClass('BillingAddress')">
                        <label for="BillingAddress">Morada de Faturação</label>
                        <div class="control">
                            <input name="BillingAddress" id="BillingAddress" type="text" v-model="adv.BillingAddress" />
                            <error-for field="BillingAddress" :field-value="adv.BillingAddress" :errors="Err" />
                        </div>
                    </div>
                    <div class="control-group required all-50 small-100 tiny-100" :class="getFieldsClass(['BillingZipCode4', 'BillingZipCode3'])">
                        <label for="BillingZipCode4">Código-Postal</label>
                        <div class="column-group half-horizontal-gutters">
                            <div class="control all-60">
                                <input name="BillingZipCode4" id="BillingZipCode4" size="4" maxlength="4" type="text"
                                    v-model="adv.BillingZipCode4" @keypress="onKeyPressIsNumber($event)" v-on:keyup="onZipCode4Change"/>
                            </div>
                            <div class="control all-40">
                                <input name="BillingZipCode3" id="BillingZipCode3" size="3" maxlength="3" type="text"
                                    v-model="adv.BillingZipCode3" @keypress="onKeyPressIsNumber($event)" v-on:keyup="onZipCode3Change"/>
                            </div>
                        </div>
                        <error-for fields="[`BillingZipCode4`, `BillingZipCode3`]" :fields-value="[adv.BillingZipCode4, adv.BillingZipCode3]" :errors="Err" :show-only-one-error="true"/>
                    </div>
                    <div class="control-group required all-50 small-100 tiny-100"
                        :class="getFieldClass('BillingZipCodeCity')">
                        <label for="BillingZipCodeCity">Localidade</label>
                        <div class="control">
                            <input name="BillingZipCodeCity" id="BillingZipCodeCity" type="text"
                                v-model="adv.BillingZipCodeCity" maxlength="64"/>
                        </div>
                        <error-for field="BillingZipCodeCity" :field-value="adv.BillingZipCodeCity" :errors="Err" />
                    </div>
                    <div class="control-group required all-50 small-100 tiny-100" :class="getFieldClass('IdDistrict')">
                        <label for="IdDistrict">Distrito</label>
                        <div class="control select-box">
                            <select id="IdDistrict" name="IdDistrict" v-model="adv.IdDistrict" @change="onDistrictChange"
                                :disabled="DistrictsDisabled">
                                <option value="null">Selecione...</option>
                                <option v-for="item in this.Districts" v-bind:key="item.Key" v-bind:value="item.Key">
                                    {{ item.Value }}
                                </option>
                            </select>
                        </div>
                        <error-for field="IdDistrict" :field-value="adv.IdDistrict" :errors="Err" />
                    </div>
                    <div class="control-group required all-50 small-100 tiny-100" :class="getFieldClass('IdCounty')">
                        <label for="IdCounty">Concelho</label>
                        <div class="control select-box">
                            <select id="IdCounty" name="IdCounty" v-model="adv.IdCounty" :disabled="CountiesDisabled">
                                <option value="null">Selecione...</option>
                                <option v-for="c in this.Counties" v-bind:key="c.Key" v-bind:value="c.Key">
                                    {{ c.Value }}
                                </option>
                            </select>
                        </div>
                        <error-for field="IdCounty" :field-value="adv.IdCounty" :errors="Err" />
                    </div>
                    <div class="control-group all-50 small-100 tiny-100" :class="getFieldClass('HQPhone')">
                        <label for="HQPhone">Telefone da sede</label>
                        <div class="control">
                            <input name="HQPhone" id="HQPhone" type="text" v-model="adv.HQPhone" maxlength="32"/>
                        </div>
                        <error-for field="HQPhone" :errors="Err" />
                    </div>
                    <div class="control-group all-100" :class="getFieldClass('website')">
                        <label for="website">Url do Site</label>
                        <div class="control">
                            <input name="website" id="website" type="text" v-model="adv.website" placeholder="https://" />
                        </div>
                        <error-for field="website" :errors="Err" />
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <legend>Dados do responsável</legend>
                <div class="column-group gutters">
                    <div class="control-group required all-100" :class="getFieldClass('ManagerName')">
                        <label for="ManagerName">Nome</label>
                        <div class="control">
                            <input name="managerName" id="ManagerName" type="text" v-model="adv.ManagerName" />
                        </div>
                        <error-for field="ManagerName" :field-value="adv.ManagerName" :errors="Err" />
                    </div>
                    <div class="control-group required all-100 required" :class="getFieldClass('ManagerEmail')">
                        <label for="ManagerEmail">E-mail</label>
                        <div class="control">
                            <input name="ManagerEmail" id="ManagerEmail" type="text" v-model="adv.ManagerEmail" />
                        </div>
                        <error-for field="ManagerEmail" :field-value="adv.ManagerEmail" :errors="Err" />
                    </div>
                    <div class="control-group required all-50 small-100 tiny-100" :class="getFieldClass('ManagerMobile')">
                        <label for="ManagerMobile">Telefone</label>
                        <div class="control">
                            <input name="ManagerMobile" id="ManagerMobile" type="text" v-model="adv.ManagerMobile" maxlength="32"/>
                        </div>
                        <error-for field="ManagerMobile" :field-value="adv.ManagerMobile" :errors="Err" />
                    </div>
                </div>
            </fieldset>

            <div class="control-group required" :class="getFieldClass('AcceptTermsAndConditions')">
                <ul class="control unstyled check-group">
                    <li>
                        <input id="AcceptTermsAndConditions" name="AcceptTermsAndConditions"
                            placeholder="Aceito as Condições de utilização do serviço Auto SAPO" type="checkbox"
                            value="true" v-model="adv.AcceptTermsAndConditions" />
                        <label for="AcceptTermsAndConditions">
                            <span>
                                Li e aceito as
                                <a href="//ajudasapo.blogs.sapo.pt/condicoes-de-utilizacao-auto-sapo-21153" target="_blank">
                                    Condições de utilização
                                </a>
                                do serviço Auto SAPO
                            </span>
                        </label>
                    </li>
                </ul>
                <error-for field="AcceptTermsAndConditions" :field-value="adv.AcceptTermsAndConditions" :errors="Err" />
            </div>

            <div v-if="this.advData.AcceptTermsAndConditions == false" class="control-group all-100">
                <ul class="control unstyled check-group">
                    <li>
                        <input type="checkbox" id="AcceptRgpd" name="AcceptRgpd" v-model="adv.AcceptRgpd" />
                        <label for="AcceptRgpd"><span>Autorizo o tratamento dos meus dados pessoais pela MEO para
                                comercialização de produtos e serviços, bem como para fins de marketing</span></label>
                    </li>
                </ul>
            </div>


            <div class="double-bottom-space">
                <div>
                    <small class="bottom-space">Os campos marcados com * são obrigatórios.</small>
                </div>
                <div>
                    <a v-if="!registerComponent" class="ink-button main-action push-right">Guardar</a>

                    <a href="#" v-if="registerComponent" v-on:click.prevent="onCreateAdvertiser"
                        class="ink-button main-action push-right">Registar</a>
                    <a href="/account/signup" v-if="registerComponent"
                        class="ink-button push-right">Voltar</a>
                </div>
            </div>
        </form>
    </section>
    <section class="card submited" v-else>
        <div class="push-center">
            <i class="far fa-check-circle"></i>
            <h3>Os seu dados foram enviados com sucesso</h3>
            <p>Irá receber um email com os próximos passos</p>

            <a href="/Signup">Voltar ao início</a>
            <a href="https://auto.sapo.pt/">Ir para o AutoSAPO</a>
        </div>
    </section>
</template>


<script>
import advertiserClient from "../scripts/services/advertiserClient.js";
import refdataClient from "../scripts/services/refdataClient.js";
import * as utils from "../scripts/utils.js";
import gis from "../global/gis.js";
import ErrorFor from "../global/error-for.vue";

export default {
    name: "details",
    components: {
        ErrorFor,
    },
    mixins: [gis],
    props: {
        registerComponent: Boolean,
        advertiser: String,
        advData: Object
    },

    data() {
        return {
            Districts: [],
            DistrictsDisabled: true,
            Counties: [],
            CountiesDisabled: true,
            formEdit: true,
            adv: {
                website: null,
                ManagerMobile: null,
                ManagerEmail: "",
                ManagerName: "",
                IdCounty: null,
                IdDistrict: null,
                BillingZipCodeCity: null,
                BillingZipCode3: null,
                BillingZipCode4: null,
                BillingAddress: null,
                BillingName: null,
                TaxNumber: "",
                Description: null,
                PublicName: "",
                HQPhone: null,
                HQFax: null,
                AcceptTermsAndConditions: false,
                AcceptRgpd: false,
                AcceptNewsletterAUTO: false
            },
            Err: [],
            showPageLoader: false
        };
    },

    methods: {       
        onKeyPressIsNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        onZipCode4Change(e){
            if (e.key === 'Tab' || e.key === 'Shift') {
                return;
            }

            if (this.adv.BillingZipCode4 && this.adv.BillingZipCode4.length === 4) {
                $('#BillingZipCode3').focus();
            }
        },
        onZipCode3Change(e){
            if (e.key === 'Tab' || e.key === 'Shift') {
                return;
            }

            if (this.adv.BillingZipCode3 && this.adv.BillingZipCode3.length === 3) {
                $('#BillingZipCodeCity').focus();
            }
        },
        async onDistrictChange(e) {
            this.Counties = [];
            if (e.target.value == "0") {
                this.CountiesDisabled = true;
            } else {
                this.DistrictsDisabled = true;
                this.CountiesDisabled = true;
                this.Counties = await refdataClient.GetCounties(e.target.value);
                this.DistrictsDisabled = false;
                this.CountiesDisabled = false;
            }
        },

        async onCreateAdvertiser() {
            this.showPageLoader = true;
            let result = await advertiserClient.CreateCompanyAdvertiser(this.adv, false);
            if (result.Success) {
                document.location = "/dashboard";
            } else {
                this.handleErrorMessages(result.Errors);
            }
            this.showPageLoader = false;
        },

        getFieldClass: function (field) {
            return utils.GetErrorClass(this.Err, field);
        },
        getFieldsClass: function (fields) {
            let classFields = "";
            for (let i = 0; i < fields.length; i++) {                
                classFields = utils.GetErrorClass(this.Err, fields[i]);
                if (classFields != null && classFields != ""){
                    return classFields;
                }
            }
            return classFields;
        },

        // Creates user friendly messages from the API response
        handleErrorMessages(errors) {
            this.Err = errors;

            // get all field names that have errors
            let fields = utils.translateStrings(errors.map(error => error.Field) );

            const errorMessage = `Verifique o(s) campo(s) ${fields.join(", ")}`;

            advertiserClient.OnWarning(null, errorMessage)
        },
        
    },

    async mounted() {
        this.Districts = await refdataClient.GetDistricts();
        this.DistrictsDisabled = false;

        if (this.advData != null) {
            
            if (this.advData.Name == '---'){
                this.advData.Name = "";
            }
            
            this.adv.ManagerName = this.advData.Name;
            this.adv.ManagerEmail = this.advData.Email;
            if (this.advData.Mobile){
                this.advData.Mobile = this.advData.Mobile.replaceAll(" ", "");
            }
            this.adv.ManagerMobile = this.advData.Mobile;
            this.adv.AcceptTermsAndConditions = this.advData.AcceptTermsAndConditions;
            this.adv.AcceptRgpd = this.advData.AcceptRgpd;
            this.adv.AcceptNewsletterAUTO = this.advData.AcceptNewsletterAUTO;
        }
    },
};
</script>


<style lang="less" scoped>
@import "../../styles/variables.less";
@import "../../styles/lesshat.less";

.ink-form {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}

small.bottom-space {
    display: inline-block;
}

.submited {
    text-align: center;

    h3 {
        margin-bottom: 1em;
    }

    .far {
        color: @main-color;
        font-size: 5em;
        margin-bottom: 0.5em;
    }

    a {
        display: inline-block;
        padding: 0 1em;
        margin: 0.5em;
    }
}
</style>